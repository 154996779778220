
// export const API_ENDPOINT = "http://192.168.9.250:3000" ;
// export const API_SOCKET_ENDPOINT = "http://192.168.9.250:3000" ;


// export const API_ENDPOINT = "http://104.218.120.90:3000/api" ;
// export const API_SOCKET_ENDPOINT = "http://104.218.120.90:3000" ;


export const API_ENDPOINT = "https://employee.emmobank.com/api" ;
export const API_SOCKET_ENDPOINT = "https://employee.emmobank.com" ;

// export const API_ENDPOINT = "http://localhost:3000/api" ;
// export const API_SOCKET_ENDPOINT = "http://localhost:3000" ;


// export const API_ENDPOINT = "http://196.219.144.140:3000/api";
// export const API_SOCKET_ENDPOINT = "http://196.219.144.140:3000";

// export const API_SOCKET_ENDPOINT = "http://192.168.0.250:3000" ;


//export const API_ENDPOINT = "http://104.218.120.144:3000/api" ;
//export const API_SOCKET_ENDPOINT = "http://104.218.120.144:3000" ;