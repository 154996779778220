import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
    return <div className="loading">
        <div className="spinner-grow text-muted"></div>
        <div className="spinner-grow text-primary"></div>
        <div className="spinner-grow text-success"></div>
    </div>;
}
// PersonalInfo
const PersonalInformation = Loadable({
    loader: () => import('./Views/PersonalInfo/PersonalInfo'),
    loading: Loading,
});
//permissions
const Permissions = Loadable({
    loader: () => import("./Views/Permisions/ViewPermisions/viewpermision.js"),
    loading: Loading,
})
const AddPermission = Loadable({
    loader: () => import("./Views/Permisions/AddPermision/AddPermision.js"),
    loading: Loading,
})
const EditPermission = Loadable({
    loader: () => import("./Views/Permisions/EditPermision/editpermission.js"),
    loading: Loading,
})
// Messages 
const Messages = Loadable({
    loader: () => import("./Views/Messages/AllMessages"),
    loading: Loading,
})
const ViewAttendance = Loadable({
    loader: () => import("./Views/Attendance/ViewAttendances"),
    loading: Loading,
})
// Elmoaser/attendance

//TASKS
const FinishedTasks = Loadable({
    loader: () => import("./Views/Tasks/FinishedTask/finishTask"),
    loading: Loading,
})
const ViewTasks = Loadable({
    loader: () => import("./Views/Tasks/AllTasks/viewCurrentTasks"),
    loading: Loading,
})
const ViewOldTasks = Loadable({
    loader: () => import("./Views/Tasks/OldTasks/OldTasks"),
    loading: Loading,
})
const AssignTasks = Loadable({
    loader: () => import("./Views/Tasks/AssignTasks/AssignTasks"),
    loading: Loading,
})
const EditAssignTasks = Loadable({
    loader: () => import("./Views/Tasks/EditAssign/AssignTasks"),
    loading: Loading,
})
const ReAssignTasks = Loadable({
    loader: () => import("./Views/Tasks/ReAssignTasks/ReAssignTasks"),
    loading: Loading,
})

const AssignedBooks = Loadable({
    loader: () => import("./Views/AssignedBooks/ViewBooks/viewBooks"),
    loading: Loading,
})

const EditBook = Loadable({
    loader: () => import("./Views/AssignedBooks/EditBook/EditBook"),
    loading: Loading,
})

const AttendanceReport = Loadable({
    loader: () => import("./Views/AttendanceReport/AttendanceReport"),
    loading: Loading,
})

const ReceiptExit = Loadable({
    loader: () => import("./Views/Receipt_Exit/Receipt_Exit"),
    loading: Loading,
})

const ViewEmpStatus = Loadable({
    loader: () => import("./Views/EmployeeStatus/ViewStatus/ViewEmpStatus"),
    loading: Loading,
})

const SearchRoom = Loadable({
    loader: () => import("./Views/SearchRoom/SearchRoom"),
    loading: Loading,
})

const gifts = Loadable({
    loader: () => import("./Views/gifts/gifts"),
    loading: Loading,
})

const assignBook = Loadable({
    loader: () => import("./Views/assign-book/assign-book"),
    loading: Loading,
})

const routes = [
    //Attendance
    { path: '/Elmoaser/attendance', exact: true, name: 'ViewAttendance', component: ViewAttendance },
    // PersonalInfo
    { path: '/Elmoaser/PersonalInformation', exact: true, name: 'Dashboard', component: PersonalInformation },
    { path: '/Elmoaser/Permissions', exact: true, name: 'Permissions', component: Permissions },
    { path: '/Elmoaser/AddPermission', exact: true, name: 'AddPermission', component: AddPermission },
    { path: '/Elmoaser/EditPermission', exact: true, name: 'EditPermission', component: EditPermission },
    // Messages
    { path: '/Elmoaser/messages', exact: true, name: 'Messages', component: Messages },
    { path: '/Elmoaser/search-room', exact: true, name: 'search-room', component: SearchRoom },
    { path: '/Elmoaser/gifts', exact: true, name: 'gifts', component: gifts },
    { path: '/Elmoaser/assign-book', exact: true, name: 'assignBook', component: assignBook },

    // TASKS
    { path: '/Elmoaser/finishedTasks', exact: true, name: 'finishedtasks', component: FinishedTasks },
    { path: '/Elmoaser/ViewTasks', exact: true, name: 'ViewTasks', component: ViewTasks },
    { path: '/Elmoaser/View-AllTasks', exact: true, name: 'ViewOldTasks', component: ViewOldTasks },
    { path: '/Elmoaser/assignTasks', exact: true, name: 'assignTasks', component: AssignTasks },
    { path: '/Elmoaser/updateAssignTasks', exact: true, name: 'editassignTasks', component: EditAssignTasks },
    { path: '/Elmoaser/reAssignTasks', exact: true, name: 'ReAssignTasks', component: ReAssignTasks },

    { path: '/Elmoaser/assignedBooks', exact: true, name: 'AssignedBooks', component: AssignedBooks },
    { path: '/Elmoaser/editbook', exact: true, name: 'EditBook', component: EditBook },
    { path: '/Elmoaser/attendance-report', exact: true, name: 'AttendanceReport', component: AttendanceReport },

    { path: '/Elmoaser/receipt-exit', exact: true, name: 'ReceiptExit', component: ReceiptExit },
    { path: '/Elmoaser/employeeStatus', exact: true, name: 'employeeStatus', component: ViewEmpStatus },

]
export default routes;