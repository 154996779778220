const Routes = [
    {
        title: "الملف الشخصي",
        url: "/Elmoaser/PersonalInformation",
        icon: "fas fa-user"
    },
    {
        title: "المهام",
        url: "/Elmoaser/ViewTasks",
        icon: "far fa-file-alt"
    },
    {
        title: "المهام المنتهيه",
        url: "/Elmoaser/finishedTasks",
        icon: "fas fa-clipboard-check"
    },
    {
        title: "كتب مسنده لي",
        url: "/Elmoaser/assignedBooks",
        icon: "fas fa-book-reader"
    },
    {
        title: "إستلام/خروج مستندات",
        url: "/Elmoaser/receipt-exit",
        icon: "fas fa-exchange-alt"
    },
    {
        title: "استعلام عن الحضور و الانصراف",
        url: "/Elmoaser/attendance-report",
        icon: "far fa-file-alt"
    },
    {
        title: "بحث عن رقم حجرة",
        url: "/Elmoaser/search-room",
        icon: "fas fa-search"
    },
    {
        title: "الرسائل",
        url: "/Elmoaser/messages",
        icon: "far fa-comment-alt",
        badge: true
    },
    {
        title: "إضافة كتاب",
        url: "/Elmoaser/assign-book",
        icon: "far fa-file-alt"
    },
    {
        title: "طلب هدية",
        url: "/Elmoaser/gifts",
        icon: "fas fa-gift"
    },
    // {
    //     title : "الاذونات و الاجازات",
    //     url : "/Elmoaser/Permissions" ,
    //     icon : "fas fa-align-justify"
    // },
    {
        title: "تسجيل الخروج",
        icon: "fas fa-sign-out-alt"
    },
]
export default Routes 